<template>
  <div class="app-container">
    <!-- 查询开始 -->
    <div>
      <!--高级搜索输入框开始 -->
      <div v-if="show" style="margin-bottom:10px;">
        <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="操作时间">
              <el-date-picker
              v-model="timeValue"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
              :picker-options="pickerOptions2"
               value-format="timestamp"
            ></el-date-picker>
            </el-form-item>
          </el-form>
      </div>
      <!--高级搜索输入框结束 -->
      <!-- 普通搜索 -->
       <el-form :inline="true"  class="demo-form-inline">
           <el-form-item label="操作模块">
            <el-select v-model="stateValue" placeholder="请选择操作模块">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
           </el-form-item>
           <el-form-item>
             <el-button type="primary" icon="el-icon-search" plain round @click="search">搜索</el-button>
              <el-button
                @click="show = !show"
                plain
                type="primary"
                :icon="show ? 'el-icon-zoom-out':'el-icon-zoom-in'"
                round
                style="border:0;"
              >高级搜索</el-button>
           </el-form-item>
        </el-form>
      <!-- 普通搜索结束 -->
      <!-- 弹框 -->
      <el-dialog title="创建角色" :visible.sync="dialogFormVisible">
        <el-form :model="form">
          <el-form-item label="名称" :label-width="formLabelWidth">
            <el-input v-model="form.name" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="备注" :label-width="formLabelWidth">
            <el-input v-model="form.remark" auto-complete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 查询结束 -->
    <!-- 表格开始 -->
    <div>
      <el-table
        border
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        :data="tableDataList"
        style="width:100%;"
        :height="this.show?650:700"
        :row-class-name="tableRowClassName"
      >
        <el-table-column label="序号" width="100">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.num }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作模块">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.OperationModule }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作类型">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.operationTypes }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作时间">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.operateTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作内容">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.operateContent }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作员">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.operateUserName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" width="268">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.remark?'无':'无' }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 表格结束 -->
    <!-- 分页开始 -->
    <div style="text-align:right">
      <Paging :totalSize="totalSize" :getMethods="getOperLog" />
    </div>
    <!-- 分页结束 -->
  </div>
</template>

<script>
import Paging from "../../components/Paging/Paging.vue";
export default {
  components: {
    Paging
  },
  data() {
    return {
      //搜索
      loading:true,
      receivablesShow: false,
      input: "", //高级查询value值
      options: [
        {
          value: "user",
          label: "用户"
        },
        {
          value: "mchali",
          label: "支付宝直连"
        },
        {
          value: "mchyz",
          label: "云纵收款"
        },
        {
          value: "projectContractor",
          label: "服务商管理"
        },
        {
          value: "shop",
          label: "商户管理"
        },
        {
          value: "role",
          label: "角色管理"
        },
        {
          value: "mchwx",
          label: "微信直连"
        },
         {
          value: "member",
          label: "员工管理"
        },
        {
          value: "orderTrade",
          label: "订单流水"
        },
        {
          value: "operations",
          label: "运营管理"
        },
        {
          value: "",
          label: "无"
        }
      ],
      // 日期选择开始
      pickerOptions2: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      timeValue: [], //微信选中的日期
      stateValue: "", //状态搜索框
      Numbering: "", //编号
      dialogFormVisible: false, //弹框值
      formLabelWidth: "80px", //弹框输入框宽度
      form: {
        //弹框表单
        name: "",
        remark: "无"
      },
      show: false, //高级搜索
      //搜索结束
      tableDataList: [],
      DataEdits: false, //编辑弹框
      roleData: {}, //编辑数据
      dialogVisibleDelete: false, //删除弹框
      rowData: {}, //删除数据

      //编辑权限
      permissions: false, //编辑权限弹框
      cities: ["上海", "北京", "广州", "深圳"],
      checkAll: false,
      checkedCities: ["上海", "北京"],
      isIndeterminate: true,
      totalSize: 0, //总条数
      pageDatalist: {},
      // 所有操作模块
      OperationModule: {
        mchali: "支付宝直连",
        mchwx: "微信直连",
        mchyz: "云纵收款",
        member: "员工管理",
        orderTrade: "订单流水",
        projectContractor: "服务商管理",
        role: "角色管理",
        shop: "商户管理",
        user: "用户",
        operations:'运营管理',
      },
      // 所有操作类型
       OperationType:{
        create: "创建",
        edit: "编辑",
        disable: "禁用",
        enable: "启用",
        delete: "删除",
        applyEdit: "编辑进件",
        downloadBill: "下载对账单",
        getAuthUrl: "拉取商户授权码",
        tradeRefund: "退款",
        editAuth: "编辑角色权限",
        uploadMaterial: "材料上传",
        downloadMaterial: "材料下载",
        imageUpload: "图片上传",
        imageDownload: "图片下载",
        doLogin: "登录",
        doLogout: "登出",
        payVerify:"支付验证 ",
        editProjectProfitApplyApplyState:'审核状态',
        editProjectProfitApplyFinanceState:'财务处理',
        applyTcash:'分润申请',
        getProjectProfitConfig:'查询分润费率',
        setProjectProfitConfig:'配置分润费率'
      }
    };
  },
  methods: {
    getOperLog(pageData) {
      this.pageDatalist = { ...pageData };
      // 日期转换成时间戳
      let Time = [],
        startTime,
        endTime,
        data = {},
        datalists = {};
      if (this.timeValue !== null) {
        Time = this.timeValue.map(item => {
          let dateTime = new Date(item);
          return dateTime.getTime();
        });
        startTime = Time[0] / 1000;
        endTime = Time[1] / 1000;
      }
      // 传入数据
      data = {
        operatePart: this.stateValue
      };
      // 判断有没有进行高级搜索，有就传入对应的时间，没有就不传
      if (Time.length !== 0) {
        datalists = {
          pageConfig: {
            orderType: "desc",
            pageSize: this.pageDatalist.pageSize,
            orderField: "id",
            pageNum: this.pageDatalist.pageNum
          },
          searchBeans: [
            //高级查询条件
            {
              fieldName: "operateTime", //字段
              startValue: startTime + "", //值
              endValue: endTime + "",
              operator: "BETWEEN" //大于等于
            }
          ],
          baseBean: data
        };
      } else {
        datalists = {
          pageConfig: {
            orderType: "desc",
            pageSize: this.pageDatalist.pageSize,
            orderField: "id",
            pageNum: this.pageDatalist.pageNum
          },
          baseBean: data
        };
      }
      this.$axios.post("/system/queryLog", datalists).then(res => {
        this.loading=false;
        this.totalSize = res.data.count;
        let datalist = res.data.systemLogTbPage; //返回数据
        let number =
          (this.pageDatalist.pageNum - 1) * this.pageDatalist.pageSize; //分页时编号计算
        datalist.map(item => {
          number++;
          item.num = number;
          item.operateTime = this.getTiem(item.operateTime);
          item.OperationModule =this.OperationModule[item.operatePart];
          item.operationTypes=this.OperationType[item.operateType]
          return item;
        });
        this.tableDataList = datalist;
      });
    },
    // 搜索
    search() {
      this.getOperLog(this.pageDatalist);
    },
    // 时间转换
    getTiem(params) {
      let unixTimestamp = new Date(params * 1000); //时间戳转换成日期
      // 定义转换返回方式
      Date.prototype.toLocaleString = function() {
        return (
          this.getFullYear() +
          "年" +
          (this.getMonth() + 1) +
          "月" +
          this.getDate() +
          "日" +
          " " +
          this.getHours() +
          "时" +
          this.getMinutes() +
          "分"
        );
      };
      params = unixTimestamp.toLocaleString();
      return params;
    },
    // 表格颜色
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return "success-row";
      }
      return "";
    }
  }
};
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .cell {
  text-align: center;
}
/* 高级搜索开始 */
.transition-box {
  margin-bottom: 10px;
  width: 200px;
  height: 50px;
  border-radius: 4px;
  background-color: #409eff;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
  margin-right: 20px;
}
#pag {
  position: fixed;
  bottom: 40px;
  right: 10px;
}


/* 高级搜索结束 */
.el-table td {
  padding: 10px 0;
}
</style>